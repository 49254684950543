import { InfiniteScrollCustomEvent, NavParams , AlertController,
   IonAccordionGroup, LoadingController, ModalController, ToastController } from '@ionic/angular';
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Router } from '@angular/router';
import { Component, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { GroupService, SessionService, UserService } from '@wtm/wtmlibrary';
import { ScanData } from 'src/app/models/user';
import { DomSanitizer } from '@angular/platform-browser';
import { Capacitor } from '@capacitor/core';
import { Generic } from 'src/app/helpers/generic';
import { CheckUploadService } from 'src/app/services/check-upload/check-upload.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.page.html',
  styleUrls: ['./user.page.scss'],
})
export class UserPage implements OnInit {

  @ViewChild(IonAccordionGroup, { static: false }) accordionGroup: IonAccordionGroup;

  users = [];
  groups = [];

  loading = true;

  page = 1;
  size = 100000;
  orgid = 0;
  groupid = 0;

  name: string;

  session: any;

  mygroup: any;

  typeid = 0;
  sessid = 0;

  colorpalette = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
                  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
                  '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
                  '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
                  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
                  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
                  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
                  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
                  '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
                  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

  colorpalette2 = ['#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
                  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
                  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
                  '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
                  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
                  '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
                  '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
                  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF',
                  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
                  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933'];

  islocal = false;
  isPopoverOpen = false;
  idcard: string;

  iscompliance = false;
  sessionId = 0;
  nomore: boolean;

  uids = [];

  quickConfirmation = false;
  isQuickMode = false;

  userid = 0;

  constructor(
    private groupService: GroupService,
    private loadingCtrl: LoadingController,
    private translateService: TranslateService,
    private userService: UserService,
    private modalCtrl: ModalController,
    private router: Router,
    private sessionAttendance: SessionService,
    private alertController: AlertController,
    private toastController: ToastController,
    private navParam: NavParams,
    private domSanitizer: DomSanitizer,
    private zone: NgZone,
    private generic: Generic,
    private checkUploadService: CheckUploadService
    ) {
    if (this.navParam && this.navParam.data) {
      this.islocal = this.navParam.data.islocal;
    }
  }

  async ngOnInit() {
    // component initialization
  }

  async ionViewWillEnter() {
    this.orgid = +localStorage.getItem('organisationId');
    this.userid = +localStorage.getItem('userId');
    const all = await firstValueFrom(this.translateService.get('ANALYTICS.All'));
    const gp: any = {};
    gp.groupId = 0;
    gp.groupName = all;
    this.groups.push(gp);
    this.mygroup = this.groups[0];

    this.session = JSON.parse(localStorage.getItem('session-info'));
    if (this.session !== undefined && this.session !== null) {
      this.typeid = this.session.sessionType.sessionTypeId;
      this.sessid = this.session.attendanceSession.sessionId;
      this.isQuickMode = this.session.sessionType.quickMode === 'Y' ? true : false;
    }

    await this.getGroups(this.typeid);
  }

  onInput(ev: any) {
    this.name = ev.target.value;
  }

  async userSelected(item: any) {
    if (!this.iscompliance) {
      return;
    }

    const scandata = new ScanData();
    scandata.groupInfo = item.groups;
    scandata.userInfo = item.mainUser;

    localStorage.setItem('scan-info', JSON.stringify(scandata));
    // navigate to confirm view
    await this.router.navigateByUrl('compliance-confirm');

    this.modalCtrl.dismiss(scandata, 'confirm');
  }

  async getGroups(sessionId: number) {
    await this.showLoading();

    const gps = await this.groupService.getSessionGroupsLocal(sessionId);

    if (gps !== undefined && gps !== null && gps.length > 0) {
      for (const val of gps) {
        if (!this.groups.includes(val)) {
          this.groups.push(val);
        }
      }
    } else {
      this.groups = [];
    }

    this.hideLoading();
  }

  groupSelected(event) {
    this.groupid = event.detail.value.groupId;
  }

  apply() {
    this.page = 1;
    this.getUsers(true);
  }

  async getUsers(clear = false) {
    if (this.page === 1) {
      await this.showLoading();
      this.loading = true;
    }

    this.zone.run(async () => {
      let params = {page: 0, size: 0, withRelated: 1, withGroups: 0, withConfirmations: 0};
      if (this.iscompliance) {
        params = {page: this.page, size: 100, withRelated: 0, withGroups: 1, withConfirmations: 0};
      }

      const usrs = await this.userService.getSessionUsersLocal(this.sessid, 0, this.name, this.groupid, params);
      // const usrs = await this.userService.getSessionUsersLocal(this.sessid, 0, '', 0, params)
      // console.log('UUUUUUUUU', usrs)
      if (clear) {
        this.users = [];
        this.uids = [];
      }

      if (usrs !== undefined && usrs !== null && usrs.length > 0) {
        const myusers = [];
        let count = 0;
        let pcount = 0;
        for (const val of usrs) {
          if (val.mainUser.image !== undefined && val.mainUser.image !== null && val.mainUser.image !== '') {
            val.img = Capacitor.convertFileSrc(val.mainUser.image);
            // val.img = this.domSanitizer.bypassSecurityTrustUrl(val.mainUser.image)
          } else {
            val.img = null;
            if (val.mainUser.lastName === '' || val.mainUser.lastName === undefined || val.mainUser.lastName === null) {
              const uname = val?.mainUser?.firstName?.split(' ');
              const vv = uname[0].split('', 2)[0] + uname[1].split('', 2)[0];
              val.initials = vv.toUpperCase();
            } else {
              const vv = val?.mainUser?.firstName?.split('', 2)[0] + val?.mainUser?.lastName?.split('', 2)[0];
              val.initials = vv.toUpperCase();
            }
          }

          val.color = this.colorpalette[count];
          count += 1;
          if (count === this.colorpalette.length) {
            count = 0;
          }

          val.classes = [];
          for (const grp of val.groups) {
            if (!val.classes.includes(grp.groupName)) {
              val.classes.push(grp.groupName);
            }
          }

          const rpersons = [];
          let isrelated = false;
          let sameperson = false;
          let confirmed = false;
          let sameconfirmed = false;
          let relatedconfirmed = false;

          if (!this.iscompliance) {
            for (const v of val.relatedPersons) {
              v.conf = false;
              // check if confirmed
              if (val.confirmed > 0) {
                confirmed = true;
              }

              if (val.confirmed === undefined && val.confirmations !== undefined && val.confirmations !== null) {
                for (const conf of val.confirmations) {
                  if (conf.confirmation.participant === val.mainUser.userId && conf.confirmation.cardId !== undefined
                      && conf.confirmation.cardId !== null && conf.confirmation.cardId !== '') {
                    // confirmed
                    confirmed = true;
                  }
                }
              }

              // check if same person
              if (v.card.cardId !== undefined && v.card.cardId !== null && v.card.cardId !== ''
                  && v.person.personId === 0
                  // && v.user.userId === val.mainUser.userId
                  ) {
                sameperson = true;
                val.samecard = v.card;

                // check if confirmation was done by same person
                for (const conf of val.confirmations) {
                  if (confirmed && conf.confirmation.cardId === v.card.cardId) {
                    // same person confirmed
                    sameconfirmed = true;
                    val.cid = conf.confirmation.cardId;
                  }
                }
              }

              // check if related person
              if (v.card.cardId !== undefined && v.card.cardId !== null && v.card.cardId !== ''
                  && v.person.personId > 0
                  // && v.user.userId !== val.mainUser.userId
                  ) {
                // check if confirmation was done by related person
                for (const conf of val.confirmations) {
                  if (confirmed && conf.confirmation.cardId === v.card.cardId) {
                    // related person confirmed
                    relatedconfirmed = true;
                    val.confirmedperson = v.person;
                    v.conf = true;
                    val.cid = conf.confirmation.cardId;
                  }
                }

                v.relatedcard = v.card;
                isrelated = true;
                const vp = v?.person?.firstName?.split('', 2)[0] + v?.person?.lastName?.split('', 2)[0];
                v.initials = vp.toUpperCase();

                v.color = this.colorpalette2[pcount];
                pcount += 1;
                if (pcount === this.colorpalette2.length) {
                  pcount = 0;
                }

                rpersons.push(v);
              }
            }
          }

          val.isrelated = isrelated;
          val.sameperson = sameperson;
          val.confirmed = confirmed;
          val.sameconfirmed = sameconfirmed;
          val.relatedconfirmed = relatedconfirmed;
          val.myRelatedPersons = rpersons;

          if (!this.uids.includes(val.mainUser.userId)) {
            if (this.iscompliance) {
              this.users.push(val);
            } else {
              myusers.push(val);
            }

            this.uids.push(val.mainUser.userId);
          }
        }

        if (!this.iscompliance) {
          this.users = myusers;
        }
      } else if (!this.iscompliance || (this.iscompliance && this.page === 1)) {
        this.users = [];
      }
      this.loading = false;
      this.hideLoading();
    });

  }

  async loadMore(event) {
    if (!this.iscompliance) {
      (event as InfiniteScrollCustomEvent).target.complete();
      return;
    }
    this.nomore = false;
    this.page += 1;
    await this.getUsers();
    (event as InfiniteScrollCustomEvent).target.complete();
  }

  toggle(id, val) {
    if (val) {
      document.getElementById('forward' + id).hidden = !document.getElementById('forward' + id).hidden;
      document.getElementById('down' + id).hidden = !document.getElementById('down' + id).hidden;
    }
  }

  show(id) {
    document.getElementById(id).hidden = false;
    document.getElementById('eye' + id).hidden = true;
  }

  async directConfirm(personinfo) {
    this.generic.logEvent('performDirectConfirmation', { data: JSON.stringify(personinfo) });

    const sid = this.session.attendanceSession.sessionId;
    let cid = personinfo.samecard.cardId;
    if (personinfo.relatedPersons !== undefined && personinfo.relatedPersons !== null && personinfo.relatedPersons.length > 0) {
      for (const val of personinfo.relatedPersons) {
        if (val.card.isEnabled === 'Y') {
          cid = val.card.cardId;
        }
      }
    }
    await this.confirmAttendance(sid, cid);
  }

  ccid = null;
  ssid = 0;
  async indirectConfirm(personinfo, card) {
    this.generic.logEvent('performIndirectConfirmation', { data: JSON.stringify(card) });

    this.isPopoverOpen = true;
    this.quickConfirmation = false;

    const sid = this.session.attendanceSession.sessionId;
    const cid = card.cardId;
    this.ccid = cid;
    this.ssid = sid;
    // await this.confirmAttendance(sid, cid)
  }

  async confirmId() {
    this.generic.logEvent('performIdCardConfirmation', { idCard: JSON.stringify(this.idcard) });

    const type = this.quickConfirmation ? 'Q' : 'M';
    const idc = this.quickConfirmation ? '' : this.idcard;
    await this.confirmAttendance(this.ssid, this.ccid, 'IC', idc, type);
    // await this.confirmAttendance(this.ssid, this.ccid, 'IC', this.idcard)
  }

  cancelId() {
    this.idcard = null;
    this.isPopoverOpen = false;
  }

  textChanged(event) {
    this.idcard = event.detail.value;
  }

  async cancel() {
    await this.modalCtrl.dismiss(null, 'cancel');
  }

  async confirmAttendance(sessionid, cardid, mode = '', idcard = '', type = 'M') {
    let result: any;
    if (this.islocal) {
      result = await this.sessionAttendance.confirmAttendance(
        sessionid,
        cardid,
        type,
        0,
        mode,
        idcard,
        this.userid
      );
    } else {
      await this.showLoading();
      result = await this.sessionAttendance.confirmAttendanceOnline(sessionid, cardid, type, 0, mode, idcard, this.userid)
      .catch(err => console.log('error', err));
      await this.hideLoading();
    }

    this.generic.logEvent('confirmAttendance', { confirmAttendanceResponse: JSON.stringify(result) });

      // check if session expired
    if (result === 'session expired') {
      // display session expired message
      const info = await firstValueFrom(this.translateService.get('GENERAL.Information'));
      const val = await firstValueFrom(this.translateService.get('ERROR.SessionExpired'));
      const button = await firstValueFrom(this.translateService.get('GENERAL.Close'));
      const alert = await this.alertController.create({
        header: info,
        message: val,
        buttons: [button]
      });

      await alert.present();
      return;
    }

    // check if session not yet due
    if (result === 'validity not valid') {
      // display session expired message
      const info = await firstValueFrom(this.translateService.get('GENERAL.Information'));
      const val = await firstValueFrom(this.translateService.get('ERROR.SessionNotDue'));
      const button = await firstValueFrom(this.translateService.get('GENERAL.Close'));
      const alert = await this.alertController.create({
        header: info,
        message: val,
        buttons: [button]
      });

      await alert.present();
      return;
    }

    if (result !== undefined && result !== null && result.error !== undefined
        && result.error !== null) {

      if (result.info !== undefined && result.info !== null) {
        const val = result.error + ' (' + result.info + ')';
        await this.showFailed(val, result.user.firstName + ' ' + result.user.lastName);
      }

      if (result.error === 'user card not found') {
        const val = await firstValueFrom(this.translateService.get('ERROR.UserCardNotFound'));

        await this.showFailed(val, '');
      }

      if (result.error === 'card not enabled') {
        const val = await firstValueFrom(this.translateService.get('ERROR.CardNotEnabled'));

        await this.showFailed(val, result.user.firstName + ' ' + result.user.lastName);
      }

      if (result.error === 'user not authorized to attend session') {
        const val = await firstValueFrom(this.translateService.get('ERROR.UserNotInParticipantsList'));

        await this.showFailed(val, result.user.firstName + ' ' + result.user.lastName);
      }

      if (result.error === 'person is not an authorized escort') {
        const val = await firstValueFrom(this.translateService.get('ERROR.PersonNotAnAuthorizedEscort'));

        await this.showFailed(val, result?.user?.firstName + ' ' + result?.user?.lastName);
      }

      if (result.error === 'attendance already confirmed') {
        const val = await firstValueFrom(this.translateService.get('ERROR.AttendanceAlreadyConfirmed'));

        await this.showFailed(val, result.user.firstName + ' ' + result.user.lastName);
      }

      if (result.error === 'id card does not match') {
        const val = await firstValueFrom(this.translateService.get('ERROR.IDCARDNOTMATCH'));

        await this.showFailed(val, result.user.firstName + ' ' + result.user.lastName);
      }

      return;
    }

    if (result.firstName !== null && result.firstName !== undefined) {
      this.isPopoverOpen = false;
      await this.showSuccess(result.firstName + ' ' + result.lastName);
    } else {
      await this.showFailed('Confirmation Failed ', '');
    }

    if (this.islocal) {
      this.checkUploadService.setUploaded('N');
    }
  }

  async showFailed(val, name) {
    await this.presentToast(val, 2000, 'danger');
  }

  async showSuccess(name) {
    const val = await firstValueFrom(this.translateService.get('GENERAL.AttendanceConfirmed'));
    await this.presentToast(val, 2000, 'success');
    this.uids = [];
    await this.getUsers();
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async presentToast(msg: string, dur: number, color: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: dur,
      color,
      position: 'top',
      cssClass: 'ion-text-center',
    });
    toast.present();
  }

  async presentAlert() {
    const title = await firstValueFrom(this.translateService.get('SESSION.PLEASEENTERIDCARD'));
    const ok = await firstValueFrom(this.translateService.get('GENERAL.OK'));
    const cancel = await firstValueFrom(this.translateService.get('GENERAL.Cancel'));
    const name = await firstValueFrom(this.translateService.get('SESSION.NAME'));
    const alert = await this.alertController.create({
      header: title,
      buttons: [cancel, ok],
      inputs: [
        {
          placeholder: name,
        },
      ],
    });

    await alert.present();
  }

  loadEl: any;

  async showLoading() {
    const val = await firstValueFrom(this.translateService.get('GENERAL.Processing')) + '...';
    this.loadEl = await this.loadingCtrl
        .create({ message: val });
    this.loadEl.present();
  }

  hideLoading() {
    if (this.loadEl !== undefined && this.loadEl !== null) {
      this.loadEl.dismiss();
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event !== undefined && event !== null
        && event.key !== undefined && event.key !== null) {
      if (!this.isPopoverOpen) {
        if (event.key === 'Enter') {
          if (this.name !== undefined && this.name !== null && this.name.trim() !== ''
              && this.mygroup !== undefined && this.mygroup !== null) {
            this.apply();
          }
        }
      }
    }
  }

  quickCheck() {
    this.quickConfirmation = !this.quickConfirmation;
  }

  handleError(err, event: string) {
    console.log(err);
    this.hideLoading();
  }

}
